.asset-allocation-card {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .progress-bar-container {
    margin-top: 20px;
  }
  
  .account-breakdown-list {
    margin-top: 20px;
  }
  
  .account-breakdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .account-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  