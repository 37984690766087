/* src/styles/components/LoginPage.css */

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: linear-gradient(115deg, #576574, #a19393);
  }
  
  
  .login-container {
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.05); /* More transparent */
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    padding: 1.5rem; /* Slightly smaller padding */
    max-width: 400px;
    width: 100%;
  }
  
  .login-container h1 {
    font-family: 'CInter SemiBold', sans-serif;
    color: #ffffff;
    margin-bottom: 0.75rem; /* Slightly smaller margin */
    text-align: center;
  }
  
  .login-container p {
    font-family: 'CInter Regular', sans-serif;
    color: #ffffff;
    margin-top: 0.75rem; /* Slightly smaller margin */
    text-align: center;
  }
  
  .login-container label {
    font-family: 'CInter Medium', sans-serif;
    color: #ffffff;
    display: block;
    text-align: left;
    margin-bottom: 0.25rem; /* Slightly smaller margin */
  }
  
  .login-container .form-group {
    margin-bottom: 0.75rem; /* Slightly smaller margin */
    position: relative;
  }
  
  .login-container .form-control {
    font-family: 'CInter Light', sans-serif;
    background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0.5rem;
    font-size: 0.8rem; /* Smaller text */
    padding: 0.5rem 1rem 0.5rem 2.5rem; /* Add padding for icons */
    margin-bottom: 0.5rem;
  }
  
  .login-container .form-control::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem; /* Smaller placeholder text */
  }
  
  .login-container .input-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.7);
  }
  
  .login-container .toggle-password {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }
  
  .login-container .btn {
    font-family: 'CInter SemiBold', sans-serif;
    background-color: #000000;
    border: none;
    border-radius: 0.5rem;
    color: #ffffff;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
  }
  
  .login-container .btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .login-container a {
    color: var(--user-link-color);
    text-decoration: none;
  }
  
  .login-container a:hover {
    text-decoration: underline;
  }
  
  .login-error-message {
    color: red;
    margin-bottom: 1rem;
    text-align: center;
  }
  